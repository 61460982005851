import './App.css';
import {useState} from 'react';

function SwitchableMap(){
  const [map_url, set_map_url] = useState("/static/maps/AmericanIndian.html");

  const handle_change = (event) => {
    let selected_value = event.target.value;
    let new_url = "";

    switch(selected_value){
      case "AI":
        new_url = "/static/maps/AmericanIndian.html";
        break;
      case "A":
        new_url = "/static/maps/Asian.html";
        break;
      case "HL":
        new_url = "/static/maps/Latino.html";
        break;
      case "NH":
        new_url = "/static/maps/Hawaiian.html";
        break;
      case "TM":
        new_url = "/static/maps/TwoOrMoreRaces.html";
        break;
      case "B":
        new_url = "/static/maps/Black.html";
        break;
      case "W":
        new_url = "/static/maps/white.html";
        break;
      case "MFI":
        new_url = "/static/maps/MedianFamilyIncome.html";
        break;
      case "MHI":
        new_url = "/static/maps/MedianHouseholdIncome.html";
        break;
      case "PB150":
        new_url = "/static/maps/PeopleBelow150Poverty.html";
        break;
      case "PBP":
        new_url = "/static/maps/PercentBelowPoverty.html";
        break;
      case "PL9":
        new_url = "/static/maps/PercentLessThan9th.html";
        break;
      case "P3":
        new_url = "/static/maps/PercentNo.html";
        break;
      case "WB":
        new_url = "/static/maps/WithoutBachelorsPercent.html";
        break;
    }

    set_map_url(new_url);
  }
  return (
    <div className="SwitchableMap">
      <iframe src={map_url} title="Map1"></iframe>
      <label for="variables">Choose a variable:</label>
      <select name="map variable" id="variables" onChange={handle_change}>
        <option value="AI">American Indian or Alaskan Native</option>
        <option value="A">Asian</option>
        <option value="HL">Hispanic or Latino</option>
        <option value="NH">Native Hawaiian</option>
        <option value="TM">Two or More Races</option>
        <option value="B">Black</option>
        <option value="W">White</option>
        <option value="MFI">Median Family Income</option>
        <option value="MHI">Median Household Income</option>
        <option value="PB150">People Below 150% Poverty</option>
        <option value="PBP">Percent Below Poverty</option>
        <option value="PL9">Percent Less than 9th Grade Education</option>
        <option value="P3">Percent No Prop 3 (Percent who voted pro life)</option>
        <option value="WB">Without Bachelors %</option>
      </select>
      <br></br>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <h1>Hi!</h1>
      <div className="RowOfMaps">
        <SwitchableMap></SwitchableMap>
        <SwitchableMap></SwitchableMap>
      </div>
    </div>
  );
}

export default App;
